import { getDateOnlyFromDate } from '@@helpers/dateOnlyHelpers';
import * as UI from '@@ui';
import _ from 'lodash';
import React from 'react';
import {
  RangeDatepicker,
  RangeDatepickerProps,
} from './chakra-dayzed-datepicker';

/**
 * A wrapper for the RangeDatepicker component that provides default props.
 */
export type DateRangeInputProps = RangeDatepickerProps & {
  isReadOnly?: boolean;
};
export const DateRangeInput: React.FC<DateRangeInputProps> = (props) => {
  if (props.isReadOnly) {
    // The readOnly select is still interactive, so we display this instead
    return (
      <UI.Input
        isReadOnly
        value={
          props.selectedDates.map((d) => getDateOnlyFromDate(d)).join(' to ') ||
          ''
        }
      />
    );
  }

  const defaultProps: Partial<DateRangeInputProps> = {
    propsConfigs: {
      inputProps: {
        cursor: 'pointer',
      },
    },
    configs: {
      dateFormat: 'MM/dd/yyyy',
    },
  };
  return <RangeDatepicker {..._.merge(defaultProps, props)} />;
};
