/**
 * This module shares code with the API. Be sure to reflect any changes in both places.
 */

export enum PermissionsEnum {
  UNDEFINED = 1,
  // Admin Menu / Endpoint
  ADMIN_SERVICES_FULL_ACCESS,
  ADMIN_PROGRAM_CALENDAR_FULL_ACCESS,
  ADMIN_PROGRAM_CALENDAR_CLASS_ACCESS,
  ADMIN_CLASS_CAN_ADD, // CHANGE FROM API: ADDED
  ADMIN_CLASS_CAN_DELETE,
  ADMIN_CLASS_CAN_MODIFY,
  ADMIN_CLASS_SKILLS_CAN_MODIFY,
  ADMIN_CLASS_INFO_ATTENDANCE_CAN_MODIFY,
  ADMIN_CLASS_INFO_CAN_VIEW_DETAILS,
  ADMIN_CLASS_INFO_CAN_ADD, // CHANGE FROM API: ADDED
  ADMIN_CLASS_INFO_CAN_CLONE,
  ADMIN_CLASS_INFO_CAN_DELETE,
  ADMIN_CLASS_INFO_CAN_MODIFY,
  ADMIN_CLASS_INFO_REGISTRATION_CAN_VIEW,
  ADMIN_CLASS_INFO_REGISTRATION_CAN_MODIFY,
  ADMIN_CLASS_INFO_REGISTRATION_CAN_CANCEL, // CHANGE FROM API: ADDED
  ADMIN_CLASS_INFO_REGISTRATION_CAN_DELETE, // CHANGE FROM API: ADDED
  ADMIN_CLASS_INFO_SKILLS_CAN_VIEW,
  ADMIN_CLASS_INFO_SKILLS_CAN_MODIFY,
  ADMIN_CLASS_INFO_TEACHER_PERMISSION_CAN_MODIFY,
  ADMIN_REPORT_CAN_ACCESS,
  ADMIN_EVENTS_FULL_ACCESS,
  ADMIN_PROGRAM_CAN_ACCESS,
  ADMIN_FRONT_DESK_FULL_ACCESS, // CHANGE FROM API: ADDED
  ADMIN_FRONT_DESK_SETTINGS_CAN_ACCESS, // CHANGE FROM ADMIN: ADDED
  ADMIN_AGENCY_ASSISTANCE_FULL_ACCESS, // CHANGE FROM API: ADDED
  ADMIN_AGENCY_ASSISTANCE_LOGS_CAN_ACCESS, // CHANGE FROM API: ADDED
  ADMIN_USER_MANAGER_CAN_ACCESS,
  ADMIN_PAYMENT_GATEWAY_FULL_ACCESS,
  ADMIN_SETTINGS_FULL_ACCESS,
  // Staff Portal - Family - Caregiver
  FAMILY_CAN_ADD_NEW,
  FAMILY_CAN_EDIT_INFO,
  FAMILY_CAN_EDIT_ACCOUNT_STATUS, // CHANGE FROM API: ADDED
  FAMILY_CAN_EDIT_BILL_TO_ACCOUNT, // CHANGE FROM API: ADDED
  FAMILY_CAN_VIEW,
  FAMILY_CAN_VIEW_INFO,
  // Staff Portal - Family - Caregiver
  FAMILY_CAREGIVER_CAN_VIEW_DETAILS,
  FAMILY_CAREGIVER_CAN_VIEW_HISTORY,
  FAMILY_CAREGIVER_CAN_MODIFY,
  FAMILY_CAREGIVER_CAN_MODIFY_CAN_LOG_IN,
  FAMILY_CAREGIVER_CAN_DELETE_RESTORE,
  FAMILY_CAREGIVER_CAN_UPDATE_DISPLAY_ORDER,
  // Staff Portal - Family - Child
  FAMILY_CHILD_CAN_VIEW_DETAILS,
  FAMILY_CHILD_CAN_MODIFY_ALL,
  FAMILY_CHILD_CAN_DELETE_RESTORE,
  FAMILY_CHILD_CAN_UPDATE_DISPLAY_ORDER,
  // Staff Portal - Family - Class Enrollments
  FAMILY_CLASS_ENROLLMENT_CAN_ACCESS, // CHANGE FROM API: ADDED
  FAMILY_CLASS_ENROLLMENT_CAN_CANCEL, // CHANGE FROM API: ADDED
  FAMILY_CLASS_ENROLLMENT_CAN_EDIT, // CHANGE FROM API: ADDED
  FAMILY_CURRENT_CLASSES_CAN_ACCESS, // CHANGE FROM API: ADDED
  FAMILY_CURRENT_CLASSES_CAN_CANCEL_UNCANCEL, // CHANGE FROM API: ADDED
  FAMILY_CURRENT_CLASSES_CAN_DELETE_RESTORE, // CHANGE FROM API: ADDED
  FAMILY_PREVIOUS_CLASSES_CAN_ACCESS, // CHANGE FROM API: ADDED
  FAMILY_CLASS_ENROLLMENT_CAN_MOVE_FROM_WAITLIST, // CHANGE FROM API: ADDED
  // Staff Portal - Family - Images
  FAMILY_IMAGE_CAN_MODIFY,
  // Staff Portal - Family - Ledger Overview
  FAMILY_LEDGER_OVERVIEW_CAN_ACCESS,
  // Staff Portal - Family - Make Payment
  FAMILY_MAKE_PAYMENT_CAN_ACCESS, // CHANGE FROM API: ADDED
  // Staff Portal - Family - Notes
  FAMILY_NOTES_CAN_ACCESS_AND_MODIFY,
  // Staff Portal - Family - Signed Documents
  FAMILY_SIGNED_DOCUMENTS_CAN_ACCESS,
  // Staff Portal - Family - Payment Profile
  FAMILY_PAYMENT_PROFILE_CAN_MODIFY,
  FAMILY_PAYMENT_PROFILE_CAN_REMOVE_AUTOMATIC_PAYMENT_SELECTION,
  // Staff Portal - Family - Program enrollment
  FAMILY_PROGRAM_ENROLLMENT_CAN_ACCESS, // CHANGE FROM API: ADDED
  FAMILY_PROGRAM_ENROLLMENT_CAN_ADJUST_PRICE_OVERRIDE,
  FAMILY_PROGRAM_ENROLLMENT_CAN_CANCEL,
  FAMILY_PROGRAM_ENROLLMENT_CAN_EDIT,
  FAMILY_PROGRAM_ENROLLMENT_CAN_MOVE_FROM_WAITLIST,
  FAMILY_PROGRAM_ENROLLMENT_CAN_SETUP_ADDITIONAL_SCHEDULES,
  FAMILY_PROGRAM_ENROLLMENT_CAN_SKIP_AVAILABILITY_CHECK, // CHANGE FROM API: ADDED
  // Staff Portal - Family - Current programs
  FAMILY_CURRENT_PROGRAMS_CAN_ACCESS, // CHANGE FROM API: ADDED
  FAMILY_CURRENT_PROGRAMS_CAN_DELETE, // CHANGE FROM API: ADDED
  FAMILY_PROGRAM_HISTORY_CAN_ACCESS, // CHANGE FROM API: ADDED
  FAMILY_PREVIOUS_PROGRAMS_CAN_ACCESS, // CHANGE FROM API: ADDED
  // Staff Portal - Family - Upcoming events
  FAMILY_UPCOMING_EVENTS_CAN_ACCESS,
  // Staff Portal - Global
  GLOBAL_LEDGER_CAN_ACCESS,
  GLOBAL_LEDGER_REQUIRES_SCHOOL_FILTER,
  GLOBAL_CALENDAR_CAN_ACCESS, // CHANGE FROM API: ADDED
  // Staff Portal - Shared
  LEDGER_CAN_ADD,
  LEDGER_CAN_DELETE,
  LEDGER_CAN_EDIT,
  LEDGER_CAN_VIEW_DELETED,
  LEDGER_CAN_VIEW_HISTORY, // CHANGE FROM API: ADDED
}
