/**
 * This module shares code with the API. Be sure to reflect any changes in both places.
 */

import { PermissionsEnum } from '@@auth/types/permissionsEnum';
import { RoleEnum } from '@@auth/types/roleEnum';

export const getAllowedRolesForPermission = (
  toPerform: PermissionsEnum
): RoleEnum[] => {
  let response;
  switch (toPerform) {
    // CHANGE FROM API: UPDATED PERMISSION
    case PermissionsEnum.ADMIN_SERVICES_FULL_ACCESS:
      response = [RoleEnum.TOP_ADMIN];
      break;
    // CHANGE FROM API: UPDATED PERMISSION
    case PermissionsEnum.ADMIN_EVENTS_FULL_ACCESS:
    case PermissionsEnum.ADMIN_USER_MANAGER_CAN_ACCESS:
      response = [RoleEnum.TOP_ADMIN, RoleEnum.REGIONAL_ADMIN];
      break;
    case PermissionsEnum.ADMIN_PAYMENT_GATEWAY_FULL_ACCESS:
      response = [RoleEnum.TOP_ADMIN, RoleEnum.REGIONAL_ADMIN];
      break;
    case PermissionsEnum.ADMIN_SETTINGS_FULL_ACCESS:
      response = [RoleEnum.TOP_ADMIN];
      break;
    // CHANGE FROM API: ADDED PERMISSION
    case PermissionsEnum.ADMIN_REPORT_CAN_ACCESS:
      response = [
        RoleEnum.TOP_ADMIN,
        RoleEnum.REGIONAL_ADMIN,
        RoleEnum.DIRECTOR_ADMIN,
        RoleEnum.SUPERVISOR,
        RoleEnum.SALES,
        RoleEnum.RECEPTIONIST,
      ];
      break;
    // CHANGE FROM API: UPDATED PERMISSION
    case PermissionsEnum.ADMIN_PROGRAM_CALENDAR_FULL_ACCESS:
      response = [
        RoleEnum.TOP_ADMIN,
        RoleEnum.REGIONAL_ADMIN,
        RoleEnum.DIRECTOR_ADMIN,
      ];
      break;
    // CHANGE FROM API: ADDED ROLES
    case PermissionsEnum.ADMIN_PROGRAM_CALENDAR_CLASS_ACCESS:
      response = [
        RoleEnum.TOP_ADMIN,
        RoleEnum.REGIONAL_ADMIN,
        RoleEnum.DIRECTOR_ADMIN,
        RoleEnum.SUPERVISOR,
        RoleEnum.SALES,
        RoleEnum.TEACHER,
      ];
      break;
    case PermissionsEnum.ADMIN_CLASS_INFO_CAN_ADD: // CHANGE FROM API: ADDED
    case PermissionsEnum.ADMIN_CLASS_INFO_CAN_CLONE:
    case PermissionsEnum.ADMIN_CLASS_CAN_MODIFY:
      response = [
        RoleEnum.TOP_ADMIN,
        RoleEnum.REGIONAL_ADMIN,
        RoleEnum.DIRECTOR_ADMIN,
      ];
      break;
    case PermissionsEnum.ADMIN_CLASS_CAN_ADD: // CHANGE FROM API: ADDED
    case PermissionsEnum.ADMIN_CLASS_CAN_DELETE:
    case PermissionsEnum.ADMIN_CLASS_SKILLS_CAN_MODIFY:
    case PermissionsEnum.ADMIN_CLASS_INFO_CAN_DELETE:
    case PermissionsEnum.ADMIN_CLASS_INFO_CAN_MODIFY:
    case PermissionsEnum.ADMIN_CLASS_INFO_REGISTRATION_CAN_MODIFY:
    case PermissionsEnum.ADMIN_CLASS_INFO_REGISTRATION_CAN_CANCEL: // CHANGE FROM API: ADDED
    case PermissionsEnum.ADMIN_CLASS_INFO_REGISTRATION_CAN_DELETE: // CHANGE FROM API: ADDED
      response = [
        RoleEnum.TOP_ADMIN,
        RoleEnum.REGIONAL_ADMIN,
        RoleEnum.DIRECTOR_ADMIN,
        RoleEnum.SUPERVISOR,
      ];
      break;
    case PermissionsEnum.ADMIN_CLASS_INFO_CAN_VIEW_DETAILS:
      response = [
        RoleEnum.TOP_ADMIN,
        RoleEnum.REGIONAL_ADMIN,
        RoleEnum.DIRECTOR_ADMIN,
        RoleEnum.SUPERVISOR,
        RoleEnum.SALES,
      ];
      break;
    // CHANGE FROM API: UPDATED PERMISSION
    case PermissionsEnum.ADMIN_CLASS_INFO_ATTENDANCE_CAN_MODIFY:
    case PermissionsEnum.ADMIN_CLASS_INFO_REGISTRATION_CAN_VIEW:
    case PermissionsEnum.ADMIN_CLASS_INFO_SKILLS_CAN_VIEW:
    case PermissionsEnum.ADMIN_CLASS_INFO_SKILLS_CAN_MODIFY:
    case PermissionsEnum.ADMIN_CLASS_INFO_TEACHER_PERMISSION_CAN_MODIFY:
      response = [
        RoleEnum.TOP_ADMIN,
        RoleEnum.REGIONAL_ADMIN,
        RoleEnum.DIRECTOR_ADMIN,
        RoleEnum.SUPERVISOR,
      ];
      break;
    // CHANGE FROM API: ADDED PERMISSION
    case PermissionsEnum.ADMIN_FRONT_DESK_FULL_ACCESS:
      response = [
        RoleEnum.TOP_ADMIN,
        RoleEnum.REGIONAL_ADMIN,
        RoleEnum.DIRECTOR_ADMIN,
        RoleEnum.SUPERVISOR,
        RoleEnum.SALES,
        RoleEnum.RECEPTIONIST,
      ];
      break;
    // CHANGE FROM API: ADDED PERMISSION
    case PermissionsEnum.ADMIN_FRONT_DESK_SETTINGS_CAN_ACCESS:
      response = [RoleEnum.TOP_ADMIN];
      break;
    // CHANGE FROM API: ADDED PERMISSION
    case PermissionsEnum.ADMIN_AGENCY_ASSISTANCE_FULL_ACCESS:
      response = [RoleEnum.TOP_ADMIN];
      break;
    // CHANGE FROM API: ADDED PERMISSION
    case PermissionsEnum.ADMIN_AGENCY_ASSISTANCE_LOGS_CAN_ACCESS:
      response = [
        RoleEnum.TOP_ADMIN,
        RoleEnum.REGIONAL_ADMIN,
        RoleEnum.DIRECTOR_ADMIN,
        RoleEnum.SUPERVISOR,
      ];
      break;
    // Family Caregiver Related
    case PermissionsEnum.FAMILY_CAN_ADD_NEW:
    case PermissionsEnum.FAMILY_CAN_EDIT_INFO:
    case PermissionsEnum.FAMILY_CAN_EDIT_BILL_TO_ACCOUNT: // CHANGE FROM API: ADDED
      response = [
        RoleEnum.TOP_ADMIN,
        RoleEnum.REGIONAL_ADMIN,
        RoleEnum.DIRECTOR_ADMIN,
        RoleEnum.SUPERVISOR,
        RoleEnum.SALES,
      ];
      break;
    case PermissionsEnum.FAMILY_CAN_VIEW:
      response = [
        RoleEnum.TOP_ADMIN,
        RoleEnum.REGIONAL_ADMIN,
        RoleEnum.DIRECTOR_ADMIN,
        RoleEnum.SUPERVISOR,
        RoleEnum.SALES,
        RoleEnum.RECEPTIONIST,
      ];
      break;
    case PermissionsEnum.FAMILY_CAN_VIEW_INFO:
      response = [
        RoleEnum.TOP_ADMIN,
        RoleEnum.REGIONAL_ADMIN,
        RoleEnum.DIRECTOR_ADMIN,
        RoleEnum.SUPERVISOR,
        RoleEnum.SALES,
      ];
      break;
    // CHANGE FROM API: ADDED PERMISSION
    case PermissionsEnum.FAMILY_CAN_EDIT_ACCOUNT_STATUS:
      response = [
        RoleEnum.TOP_ADMIN,
        RoleEnum.REGIONAL_ADMIN,
        RoleEnum.DIRECTOR_ADMIN,
        RoleEnum.SUPERVISOR,
      ];
      break;
    // CHANGE FROM API: UPDATED PERMISSION
    case PermissionsEnum.FAMILY_CAREGIVER_CAN_VIEW_DETAILS:
    case PermissionsEnum.FAMILY_CAREGIVER_CAN_MODIFY:
    case PermissionsEnum.FAMILY_CAREGIVER_CAN_UPDATE_DISPLAY_ORDER:
      response = [
        RoleEnum.TOP_ADMIN,
        RoleEnum.REGIONAL_ADMIN,
        RoleEnum.DIRECTOR_ADMIN,
        RoleEnum.SUPERVISOR,
        RoleEnum.SALES,
        RoleEnum.RECEPTIONIST, // CHANGE FROM API: ADDED
      ];
      break;
    // CHANGE FROM API: ADDED PERMISSION
    case PermissionsEnum.FAMILY_CAREGIVER_CAN_DELETE_RESTORE:
      response = [
        RoleEnum.TOP_ADMIN,
        RoleEnum.REGIONAL_ADMIN,
        RoleEnum.DIRECTOR_ADMIN,
        RoleEnum.SUPERVISOR,
        RoleEnum.SALES,
        RoleEnum.RECEPTIONIST
      ];
      break;
    // CHANGE FROM API: ADDED PERMISSION
    case PermissionsEnum.FAMILY_CAREGIVER_CAN_VIEW_HISTORY:
      response = [
        RoleEnum.TOP_ADMIN,
        RoleEnum.REGIONAL_ADMIN,
        RoleEnum.DIRECTOR_ADMIN,
        RoleEnum.SUPERVISOR,
      ];
      break;
    // Family Child Related
    case PermissionsEnum.FAMILY_CAREGIVER_CAN_MODIFY_CAN_LOG_IN:
      response = [
        RoleEnum.TOP_ADMIN,
        RoleEnum.REGIONAL_ADMIN,
        RoleEnum.DIRECTOR_ADMIN,
      ];
      break;
    case PermissionsEnum.FAMILY_CHILD_CAN_MODIFY_ALL:
    case PermissionsEnum.FAMILY_CHILD_CAN_UPDATE_DISPLAY_ORDER:
      response = [
        RoleEnum.TOP_ADMIN,
        RoleEnum.REGIONAL_ADMIN,
        RoleEnum.DIRECTOR_ADMIN,
        RoleEnum.SUPERVISOR,
        RoleEnum.SALES,
        RoleEnum.RECEPTIONIST,
      ];
      break;
    case PermissionsEnum.FAMILY_CHILD_CAN_VIEW_DETAILS:
      response = [
        RoleEnum.TOP_ADMIN,
        RoleEnum.REGIONAL_ADMIN,
        RoleEnum.DIRECTOR_ADMIN,
        RoleEnum.SUPERVISOR,
        RoleEnum.SALES,
        RoleEnum.RECEPTIONIST,
        RoleEnum.TEACHER,
      ];
      break;
    case PermissionsEnum.FAMILY_CHILD_CAN_DELETE_RESTORE:
      response = [RoleEnum.TOP_ADMIN, RoleEnum.REGIONAL_ADMIN];
      break;
    // CHANGE FROM API: UPDATED PERMISSION
    case PermissionsEnum.FAMILY_CLASS_ENROLLMENT_CAN_ACCESS:
    case PermissionsEnum.FAMILY_CLASS_ENROLLMENT_CAN_CANCEL:
    case PermissionsEnum.FAMILY_CLASS_ENROLLMENT_CAN_EDIT:
    case PermissionsEnum.FAMILY_CLASS_ENROLLMENT_CAN_MOVE_FROM_WAITLIST:
    case PermissionsEnum.FAMILY_CURRENT_CLASSES_CAN_ACCESS:
    case PermissionsEnum.FAMILY_CURRENT_CLASSES_CAN_CANCEL_UNCANCEL:
    case PermissionsEnum.FAMILY_CURRENT_CLASSES_CAN_DELETE_RESTORE:
    case PermissionsEnum.FAMILY_PREVIOUS_CLASSES_CAN_ACCESS:
      response = [
        RoleEnum.TOP_ADMIN,
        RoleEnum.REGIONAL_ADMIN,
        RoleEnum.DIRECTOR_ADMIN,
        RoleEnum.SUPERVISOR,
        RoleEnum.SALES,
      ];
      break;
    case PermissionsEnum.FAMILY_IMAGE_CAN_MODIFY:
      response = [
        RoleEnum.TOP_ADMIN,
        RoleEnum.REGIONAL_ADMIN,
        RoleEnum.DIRECTOR_ADMIN,
        RoleEnum.SUPERVISOR,
        RoleEnum.SALES,
        RoleEnum.RECEPTIONIST,
      ];
      break;
    case PermissionsEnum.FAMILY_MAKE_PAYMENT_CAN_ACCESS:
    case PermissionsEnum.FAMILY_LEDGER_OVERVIEW_CAN_ACCESS:
      response = [
        RoleEnum.TOP_ADMIN,
        RoleEnum.REGIONAL_ADMIN,
        RoleEnum.DIRECTOR_ADMIN,
        RoleEnum.SUPERVISOR,
        RoleEnum.SALES,
        RoleEnum.RECEPTIONIST,
      ];
      break;
    case PermissionsEnum.FAMILY_NOTES_CAN_ACCESS_AND_MODIFY:
      response = [
        RoleEnum.TOP_ADMIN,
        RoleEnum.REGIONAL_ADMIN,
        RoleEnum.DIRECTOR_ADMIN,
        RoleEnum.SUPERVISOR,
        RoleEnum.SALES,
      ];
      break;
    case PermissionsEnum.FAMILY_PAYMENT_PROFILE_CAN_MODIFY:
      response = [
        RoleEnum.TOP_ADMIN,
        RoleEnum.REGIONAL_ADMIN,
        RoleEnum.DIRECTOR_ADMIN,
        RoleEnum.SUPERVISOR,
        RoleEnum.SALES,
      ];
      break;
    case PermissionsEnum.FAMILY_PAYMENT_PROFILE_CAN_REMOVE_AUTOMATIC_PAYMENT_SELECTION:
      response = [RoleEnum.TOP_ADMIN];
      break;
    // CHANGE FROM API: UPDATED PERMISSION
    case PermissionsEnum.FAMILY_PROGRAM_ENROLLMENT_CAN_ACCESS:
    case PermissionsEnum.FAMILY_PROGRAM_ENROLLMENT_CAN_CANCEL:
    case PermissionsEnum.FAMILY_PROGRAM_ENROLLMENT_CAN_EDIT:
    case PermissionsEnum.FAMILY_PROGRAM_ENROLLMENT_CAN_SETUP_ADDITIONAL_SCHEDULES:
    case PermissionsEnum.FAMILY_UPCOMING_EVENTS_CAN_ACCESS:
      response = [
        RoleEnum.TOP_ADMIN,
        RoleEnum.REGIONAL_ADMIN,
        RoleEnum.DIRECTOR_ADMIN,
        RoleEnum.SUPERVISOR,
        RoleEnum.SALES,
      ];
      break;
    // CHANGE FROM API: UPDATED PERMISSION
    case PermissionsEnum.FAMILY_PROGRAM_ENROLLMENT_CAN_ADJUST_PRICE_OVERRIDE:
    case PermissionsEnum.FAMILY_PROGRAM_ENROLLMENT_CAN_MOVE_FROM_WAITLIST:
      response = [
        RoleEnum.TOP_ADMIN,
        RoleEnum.REGIONAL_ADMIN,
        RoleEnum.DIRECTOR_ADMIN,
      ];
      break;
    // CHANGE FROM API: ADDED PERMISSION
    case PermissionsEnum.FAMILY_PROGRAM_ENROLLMENT_CAN_SKIP_AVAILABILITY_CHECK:
      response = [RoleEnum.TOP_ADMIN];
      break;
    case PermissionsEnum.FAMILY_SIGNED_DOCUMENTS_CAN_ACCESS:
      response = [
        RoleEnum.TOP_ADMIN,
        RoleEnum.REGIONAL_ADMIN,
        RoleEnum.DIRECTOR_ADMIN,
        RoleEnum.SUPERVISOR,
        RoleEnum.SALES,
        RoleEnum.RECEPTIONIST,
      ];
      break;
    // CHANGE FROM API: ADDED PERMISSION
    case PermissionsEnum.FAMILY_PROGRAM_HISTORY_CAN_ACCESS:
    case PermissionsEnum.FAMILY_CURRENT_PROGRAMS_CAN_DELETE:
    case PermissionsEnum.FAMILY_CURRENT_PROGRAMS_CAN_ACCESS:
      response = [
        RoleEnum.TOP_ADMIN,
        RoleEnum.REGIONAL_ADMIN,
        RoleEnum.DIRECTOR_ADMIN,
        RoleEnum.SUPERVISOR,
        RoleEnum.SALES,
      ];
      break;
    case PermissionsEnum.FAMILY_PREVIOUS_PROGRAMS_CAN_ACCESS:
      response = [
        RoleEnum.TOP_ADMIN,
        RoleEnum.REGIONAL_ADMIN,
        RoleEnum.DIRECTOR_ADMIN,
        RoleEnum.SUPERVISOR,
      ];
      break;
    // CHANGE FROM API: UPDATED PERMISSION
    case PermissionsEnum.GLOBAL_LEDGER_CAN_ACCESS:
      response = [RoleEnum.TOP_ADMIN, RoleEnum.REGIONAL_ADMIN];
      break;
    // CHANGE FROM API: UPDATED PERMISSION
    case PermissionsEnum.GLOBAL_CALENDAR_CAN_ACCESS:
      response = [
        RoleEnum.TOP_ADMIN,
        RoleEnum.REGIONAL_ADMIN,
        RoleEnum.DIRECTOR_ADMIN,
        RoleEnum.SUPERVISOR,
        RoleEnum.SALES,
      ];
      break;
    // CHANGE FROM API: REMOVED DUPLICATE CASE [LEDGER_CAN_EDIT]
    // CHANGE FROM API: UPDATED PERMISSION
    case PermissionsEnum.LEDGER_CAN_ADD:
      response = [RoleEnum.TOP_ADMIN, RoleEnum.REGIONAL_ADMIN];
      break;
    // CHANGE FROM API: UPDATED PERMISSION
    case PermissionsEnum.LEDGER_CAN_EDIT:
      response = [RoleEnum.TOP_ADMIN];
      break;
    case PermissionsEnum.LEDGER_CAN_VIEW_DELETED:
      response = [RoleEnum.TOP_ADMIN, RoleEnum.REGIONAL_ADMIN];
      break;
    case PermissionsEnum.LEDGER_CAN_DELETE:
      // LEDGER_CAN_FLAG_ACHEFT,
      response = [RoleEnum.TOP_ADMIN];
      break;
    // CHANGE FROM API: ADDED PERMISSION
    case PermissionsEnum.LEDGER_CAN_VIEW_HISTORY:
      response = [RoleEnum.TOP_ADMIN];
      break;
    default:
      // no roles
      break;
  }

  return response || [];
};
