import { RoleProtect } from '@@auth/RoleProtect';
import { useIsRouteOrChildActive } from '@@routing/helpers/useIsRouteOrChildActive';
import { AppRoute } from '@@routing/types';
import * as UI from '@@ui';
import React from 'react';
import { Link } from 'react-router-dom';

/**
 * A link to a route.
 * The link will not render if the user doesn't have the required permission.
 */

export type RouteLinkProps = {
  route: AppRoute;
  activeProps?: UI.LinkProps;
  onActive?: () => void;
  activateOnChild?: boolean;
} & UI.LinkProps;
export const RouteLink = React.forwardRef<HTMLAnchorElement, RouteLinkProps>(
  ({ route, activeProps, onActive, activateOnChild, ...restProps }, ref) => {
    const isActive = useIsRouteOrChildActive(route, activateOnChild);
    React.useEffect(() => {
      if (isActive) {
        onActive?.();
      }
    }, [isActive]);

    return (
      <RoleProtect permission={route.permission}>
        <UI.Link
          ref={ref}
          as={Link}
          to={route.path}
          children={route.label}
          {...restProps}
          {...(isActive ? activeProps : {})}
        />
      </RoleProtect>
    );
  }
);
