export enum RoleEnum {
  /**
   * name: 'Top Level',
   * origName: 'Top Level Administrator',
   */
  TOP_ADMIN = 1,
  TEACHER = 5,
  /**
   * Old 1.0 role, removed in PR: https://github.com/352Media/o2b-beekeeper-api/pull/620
   */
  // FRONT_DESK = 6,
  SALES = 7,
  /**
   * name: 'Administrator',
   * origName: 'Regional Administrator',
   */
  REGIONAL_ADMIN = 8,
  /**
   * name: 'Director',
   * origName: 'Director Administrator',
   */
  DIRECTOR_ADMIN = 9,
  RECEPTIONIST = 10,
  SUPERVISOR = 12,
  KIOSK = 13,
}
