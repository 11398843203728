import { FALLBACK_EXPIRATION_SECONDS } from '@@helpers/imageHelpers';
import axios from 'axios';
import { useMutation, useQuery } from 'react-query';
import { queryClient } from './ApiProvider';
import { BaseQueryKey } from './helpers/invalidateQueries';
import { Image } from './imageResourcesApi';
import { User } from './usersApi';

const baseApiPath = '/users/profile';
const baseQueryKey = BaseQueryKey.UserProfiles;

export const fetchCurrentUserProfile = () => {
  return axios.get<User>(`${baseApiPath}`).then((res) => res.data);
};

export const useCurrentUserProfile = () => {
  return useQuery<User, Error>([baseQueryKey], () => {
    return fetchCurrentUserProfile();
  });
};

export const useCurrentUserProfilePicture = () => {
  return useQuery<Image, Error>(
    [baseQueryKey, 'picture'],
    () => {
      return axios.get<Image>(`${baseApiPath}/picture`).then((res) => res.data);
    },
    {
      refetchInterval: (data) => {
        return (data?.expiresIn || FALLBACK_EXPIRATION_SECONDS) * 1000;
      },
      // If the user doesn't have a picture, the server errors,
      // so we don't want to retry or propagate the error on-screen.
      retry: false,
      refetchOnWindowFocus: false,
      useErrorBoundary: false,
    }
  );
};

export const useCurrentUserProfileThumbnail = () => {
  return useQuery<Image, Error>(
    [baseQueryKey, 'thumbnail'],
    () => {
      return axios
        .get<Image>(`${baseApiPath}/thumbnail`)
        .then((res) => res.data);
    },
    {
      refetchInterval: (data) => {
        return (data?.expiresIn || FALLBACK_EXPIRATION_SECONDS) * 1000;
      },
      // If the user doesn't have a picture, the server errors,
      // so we don't want to retry or propagate the error on-screen.
      retry: false,
      refetchOnWindowFocus: false,
      useErrorBoundary: false,
    }
  );
};

export const useUpdateCurrentUserProfilePicture = () => {
  return useMutation<any, Error, File>(
    (file) => {
      return axios
        .patch<any>(
          `${baseApiPath}/picture`,
          { file },
          {
            headers: {
              'content-type': 'multipart/form-data',
            },
          }
        )
        .then((res) => res.data);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(baseQueryKey);
      },
    }
  );
};
