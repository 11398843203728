import axios from 'axios';
import _ from 'lodash';
import { useQuery } from 'react-query';
import { z } from 'zod';
import { convertDataToOptions } from './helpers/convertDataToOptions';
import { zh } from './helpers/zodHelpers';

const baseApiPath = '/state-locations';
const baseQueryKey = 'state-locations';

export const stateSchema = z.object({
  id: zh.intid(),
  name: zh.string().required(),
  abbreviation: zh.string().required(),
});
export type State = z.infer<typeof stateSchema>;

export const useStates = () => {
  return useQuery<State[], Error>(baseQueryKey, () => {
    return axios
      .get<State[]>(baseApiPath)
      .then((res) => _.sortBy(res.data, 'name'));
  });
};

export const useState = (id: string | number) => {
  return useQuery<State, Error>([baseQueryKey, id], () => {
    return axios.get<State>(`${baseApiPath}/${id}`).then((res) => res.data);
  });
};

export const useStateOptions = () => {
  const result = useStates();
  return convertDataToOptions(result.data);
};
