import { queryClient } from '../ApiProvider';

/**
 * This is a proposed way to handle query invalidation, where there is a
 * overlap in data sets between queries.
 *
 * The supplied parameter represents the entity that was changed.
 * The function invalidates all queries potentially affected by the change.
 *
 * If this works well, we can use this pattern for all entities,
 * and we could add more granular invalidation if needed.
 */

export enum BaseQueryKey {
  AccountStatuses = 'account-statuses',
  AccountStatusRegions = 'account-status-regions',
  Children = 'children',
  Families = 'families',
  LedgerItems = 'ledger-items',
  LedgerItemCategories = 'ledger-item-categories',
  LedgerItemGroups = 'ledger-item-groups',
  MedicalDocumentations = 'medical-documentations',
  MedicalDocumentationRegions = 'medical-documentation-regions',
  ParentChecklists = 'parent-checklists',
  ParentChecklistRegions = 'parent-checklist-regions',
  ProgramGroupItems = 'program-group-items',
  ProgramGroupsFull = 'program-groups-full',
  Users = 'users',
  UserProfiles = 'user-profiles',
  UserRegions = 'user-regions',
  UserSchools = 'user-schools',
}
export const invalidateQueries = (affectedKey: BaseQueryKey) => {
  queryClient.invalidateQueries(affectedKey);

  // Invalidate related queries
  switch (affectedKey) {
    case BaseQueryKey.AccountStatuses:
    case BaseQueryKey.AccountStatusRegions:
      queryClient.invalidateQueries(BaseQueryKey.Families);
      break;
    case BaseQueryKey.LedgerItemCategories:
      queryClient.invalidateQueries(BaseQueryKey.LedgerItems);
      break;
    case BaseQueryKey.LedgerItems:
      queryClient.invalidateQueries(BaseQueryKey.LedgerItemGroups);
      break;
    case BaseQueryKey.MedicalDocumentations:
    case BaseQueryKey.MedicalDocumentationRegions:
    case BaseQueryKey.ParentChecklists:
    case BaseQueryKey.ParentChecklistRegions:
      queryClient.invalidateQueries(BaseQueryKey.Children);
      break;
    case BaseQueryKey.ProgramGroupsFull:
      queryClient.invalidateQueries(BaseQueryKey.ProgramGroupItems);
      break;
    case BaseQueryKey.ProgramGroupItems:
      queryClient.invalidateQueries(BaseQueryKey.ProgramGroupsFull);
      break;
    case BaseQueryKey.Users:
      queryClient.invalidateQueries(BaseQueryKey.UserProfiles);
      break;
    case BaseQueryKey.UserRegions:
    case BaseQueryKey.UserSchools:
      queryClient.invalidateQueries(BaseQueryKey.Users);
      queryClient.invalidateQueries(BaseQueryKey.UserProfiles);
      break;
    default:
      break;
  }
};
