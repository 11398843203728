import axios from 'axios';
import debounce from 'debounce-promise';
import _ from 'lodash';
import { useMutation, useQuery } from 'react-query';
import { z } from 'zod';
import { queryClient } from './ApiProvider';
import { convertDataToOptions } from './helpers/convertDataToOptions';
import { zh } from './helpers/zodHelpers';
import { QueryOptions, SearchInput, SearchResult } from './types';

const baseApiPath = '/regions';
const baseQueryKey = 'regions';

export const regionBaseInputSchema = z.object({
  name: zh.string().required(),
  timezoneId: zh.string().required(), // not a uuid
  paymentAllowCashUsage: z.boolean(),
  paymentAllowCheckUsage: z.boolean(),
  paymentAllowEftUsage: z.boolean(),
  paymentAllowCreditCardUsage: z.boolean(),
  authorizeKeyId: zh.intid(),
});

export const regionInputSchema = regionBaseInputSchema.superRefine(
  (data, ctx) => {
    if (!data.paymentAllowEftUsage && !data.paymentAllowCreditCardUsage) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Either EFT or Credit Card must be selected.',
        path: ['paymentAllowEftUsage'],
      });
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Either EFT or Credit Card must be selected.',
        path: ['paymentAllowCreditCardUsage'],
      });
    }
  }
);
export type RegionInput = z.infer<typeof regionInputSchema>;

export const regionSchema = regionBaseInputSchema.extend({
  id: zh.intid(),
  isDeleted: z.boolean(),
});
export type Region = z.infer<typeof regionSchema>;

export type RegionPaymentOptionsInput = {
  schoolId?: number;
  familyId?: string;
};

export type RegionPaymentOptions = {
  paymentAllowCashUsage: boolean;
  paymentAllowCheckUsage: boolean;
  paymentAllowEftUsage: boolean;
  paymentAllowCreditCardUsage: boolean;
};

export const useRegions = () => {
  return useQuery<Region[], Error>(baseQueryKey, () => {
    return axios
      .get<Region[]>(baseApiPath)
      .then((res) => _.sortBy(res.data, 'name'));
  });
};

export const useRegion = (id: string | number) => {
  return useQuery<Region, Error>([baseQueryKey, id], () => {
    return axios.get<Region>(`${baseApiPath}/${id}`).then((res) => res.data);
  });
};

export const useAddRegion = () => {
  return useMutation<Region, Error, RegionInput>(
    (values) => {
      return axios.post<Region>(baseApiPath, values).then((res) => res.data);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(baseQueryKey);
      },
    }
  );
};

export const useDeleteRegion = () => {
  return useMutation<boolean, Error, string | number>(
    (id) => {
      return axios
        .delete<boolean>(`${baseApiPath}/${id}`)
        .then((res) => res.data);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(baseQueryKey);
      },
    }
  );
};

export const useRestoreRegion = () => {
  return useMutation<Region, Error, string | number>(
    (id) => {
      return axios
        .patch<Region>(`${baseApiPath}/${id}/restore`)
        .then((res) => res.data);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(baseQueryKey);
      },
    }
  );
};

export const useUpdateRegion = (id: string | number) => {
  return useMutation<Region, Error, RegionInput>(
    (values) => {
      return axios
        .patch<Region>(`${baseApiPath}/${id}`, values)
        .then((res) => res.data);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(baseQueryKey);
      },
    }
  );
};

export const useRegionOptions = () => {
  const result = useRegionSearch({}, true);
  return convertDataToOptions(result.data?.data, { sort: true });
};

export const useRegionPaymentOptions = (input?: RegionPaymentOptionsInput) => {
  return useQuery<RegionPaymentOptions, Error>(
    [baseQueryKey, 'paymentoptions', input],
    () => {
      return axios
        .post<RegionPaymentOptions>(`${baseApiPath}/paymentoptions`, input)
        .then((res) => res.data);
    }
  );
};

/**
 * Limited searches
 * If truthy (default), the "limit" property will use the limited API
 * to filter results based on the current user,
 * and optionally a Family's region or Region itself,
 * if the appropriate ID is supplied.
 */
type SearchLimit =
  | boolean
  | {
      familyId: string;
    };

const regionSearch = (input: SearchInput<Region>, limit?: SearchLimit) => {
  const path = limit
    ? `${baseApiPath}/search/typeahead` // limited search
    : `${baseApiPath}/search`; // unlimited search
  return axios
    .post<SearchResult<Region>>(path, _.merge({}, input, limit))
    .then((res) => res.data);
};

export const loadRegionOptions = debounce(
  async (input: SearchInput<Region>, limit?: SearchLimit) => {
    const result = await regionSearch(input, limit);
    return convertDataToOptions(result.data);
  },
  250
);

export const loadPaginatedRegionOptions = debounce(
  async (input: SearchInput<Region>, limit?: SearchLimit) => {
    const result = await regionSearch(input, limit);
    return {
      options: convertDataToOptions(result.data),
      meta: result.meta,
    };
  },
  250
);

export const useRegionSearch = (
  input: SearchInput<Region>,
  limit?: SearchLimit,
  options?: QueryOptions
) => {
  return useQuery<SearchResult<Region>, Error>(
    [baseQueryKey, 'search', input, limit],
    () => regionSearch(input, limit),
    {
      suspense: false,
      ...options,
    }
  );
};

export const useRegionSearchOptions = (
  input: SearchInput<Region>,
  limit?: SearchLimit
) => {
  const result = useRegionSearch(input, limit);
  return convertDataToOptions(result.data?.data, { sort: true });
};
