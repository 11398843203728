import { QuickCheckInSignatureTypeEnum } from '@@helpers/quickCheckInHelpers';
import axios from 'axios';
import debounce from 'debounce-promise';
import _ from 'lodash';
import { useMutation, useQuery } from 'react-query';
import { z } from 'zod';
import { queryClient } from './ApiProvider';
import { convertDataToOptions } from './helpers/convertDataToOptions';
import { zh } from './helpers/zodHelpers';
import { regionSchema } from './regionsApi';
import { stateSchema } from './statesApi';
import { FindInput, QueryOptions, SearchInput, SearchResult } from './types';

const baseApiPath = '/schools';
const baseQueryKey = 'schools';

export const schoolInputSchema = z.object({
  name: zh.string(100).required(),
  federalId: zh.string(15).required(), // NOT a system uuid
  address1: zh.string(255).required(),
  address2: zh.string(255).nullish(),
  city: zh.string(50).required(),
  zip: zh.string(12).required(),
  stateLocationId: zh.intid(),
  salesTax: z.number(),
  regionId: zh.intid(),
  accountingCode: zh.string().required(),
  county: zh.string().nullish(),
  licenseNumber: zh.string(50).nullish(),
  licensedCapacity: z.number().int().nullish(),
  openedDateOnly: zh.string().nullish(),
  licenseRenewalDateOnly: zh.string().nullish(),
  accredidationRenewalDateOnly: zh.string().nullish(),
  overallSquareFootage: z.number().int().nullish(),
  quickCheckInSuccessMessageOverride: zh.string().nullish(),
  quickCheckInSignatureTypeId: z.nativeEnum(QuickCheckInSignatureTypeEnum),
});
export type SchoolInput = z.infer<typeof schoolInputSchema>;

export const schoolSchema = schoolInputSchema.extend({
  id: zh.intid(),
  state: stateSchema.pick({ id: true, name: true }).nullish(),
  region: regionSchema.pick({ id: true, name: true }).nullish(),
  isDeleted: z.boolean(),
});
export type School = z.infer<typeof schoolSchema>;

export const useSchools = () => {
  return useQuery<School[], Error>(baseQueryKey, () => {
    return axios
      .get<School[]>(baseApiPath)
      .then((res) => _.sortBy(res.data, 'name'));
  });
};

export const useSchool = (
  id: number | string,
  params?: FindInput & { includeState?: boolean }
) => {
  return useQuery<School, Error>([baseQueryKey, id], () => {
    return axios
      .get<School>(`${baseApiPath}/${id}`, { params })
      .then((res) => res.data);
  });
};

export const useAddSchool = () => {
  return useMutation<School, Error, SchoolInput>(
    (values) => {
      return axios.post<School>(baseApiPath, values).then((res) => res.data);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(baseQueryKey);
      },
    }
  );
};

export const useDeleteSchool = () => {
  return useMutation<boolean, Error, number | string>(
    (id) => {
      return axios
        .delete<boolean>(`${baseApiPath}/${id}`)
        .then((res) => res.data);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(baseQueryKey);
      },
    }
  );
};

export const useRestoreSchool = () => {
  return useMutation<School, Error, string | number>(
    (id) => {
      return axios
        .patch<School>(`${baseApiPath}/${id}/restore`)
        .then((res) => res.data);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(baseQueryKey);
      },
    }
  );
};

export const useUpdateSchool = (id: number | string) => {
  return useMutation<School, Error, SchoolInput>(
    (values) => {
      return axios
        .patch<School>(`${baseApiPath}/${id}`, values)
        .then((res) => res.data);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(baseQueryKey);
      },
    }
  );
};

export const useSchoolOptions = () => {
  const result = useSchools();
  return convertDataToOptions(result.data, { sort: true });
};

/**
 * Limited searches
 * If truthy (default), the "limit" property will use the limited API
 * to filter results based on the current user,
 * and optionally a Family's region or Region itself,
 * if the appropriate ID is supplied.
 */
type SearchLimit =
  | boolean
  | {
      familyId: string;
    }
  | {
      regionId: number;
    };

const schoolSearch = (input: SearchInput<School>, limit?: SearchLimit) => {
  const path = limit
    ? `${baseApiPath}/search/typeahead` // limited search
    : `${baseApiPath}/search`; // unlimited search
  return axios
    .post<SearchResult<School>>(path, _.merge({}, input, limit))
    .then((res) => res.data);
};

export const loadSchoolOptions = debounce(
  async (input: SearchInput<School>, limit?: SearchLimit) => {
    const result = await schoolSearch(input, limit);
    return convertDataToOptions(result.data);
  },
  250
);

export const loadPaginatedSchoolOptions = debounce(
  async (input: SearchInput<School>, limit?: SearchLimit) => {
    const result = await schoolSearch(input, limit);
    return {
      options: convertDataToOptions(result.data),
      meta: result.meta,
    };
  },
  250
);

export const useSchoolSearch = (
  input: SearchInput<School>,
  limit?: SearchLimit,
  options?: QueryOptions
) => {
  return useQuery<SearchResult<School>, Error>(
    [baseQueryKey, 'search', input, limit],
    () => schoolSearch(input, limit),
    {
      suspense: false,
      ...options,
    }
  );
};

export const useSchoolSearchOptions = (
  input: SearchInput<School>,
  limit?: SearchLimit
) => {
  const result = useSchoolSearch(input, limit);
  return convertDataToOptions(result.data?.data, { sort: true });
};
