import axios from 'axios';
import _ from 'lodash';
import { useQuery } from 'react-query';
import { z } from 'zod';
import { LOOKUP_CACHE_TIME } from './ApiProvider';
import { convertDataToOptions } from './helpers/convertDataToOptions';
import { zh } from './helpers/zodHelpers';

const baseApiPath = '/roles';
const baseQueryKey = 'roles';

/**
 * Roles
 */
export const roleSchema = z.object({
  id: zh.intid(),
  name: zh.string().required(),
  displayOrder: z.number().int(),
  isSystemRole: z.boolean(),
});
export type Role = z.infer<typeof roleSchema>;

export const useRoles = () => {
  return useQuery<Role[], Error>(
    baseQueryKey,
    () => {
      return axios
        .get<Role[]>(baseApiPath)
        .then((res) => _.sortBy(res.data, ['displayOrder', 'asc']));
    },
    {
      cacheTime: LOOKUP_CACHE_TIME,
    }
  );
};

export const useRoleOptions = () => {
  const result = useRoles();
  return convertDataToOptions(result.data, { sort: 'displayOrder' });
};
