export enum QuickCheckInSignatureTypeEnum {
  REQUIRE_NEVER = 1,
  REQUIRE_NEW_ALWAYS = 2,
  REQUIRE_PREFER_ON_FILE = 3,
}

export const quickCheckInSignatureTypeOptions = [
  {
    label: 'No signature required',
    value: QuickCheckInSignatureTypeEnum.REQUIRE_NEVER,
  },
  {
    label: 'Require new signature always',
    value: QuickCheckInSignatureTypeEnum.REQUIRE_NEW_ALWAYS,
  },
  {
    label: 'Require signature, but use on-file signature if we have it',
    value: QuickCheckInSignatureTypeEnum.REQUIRE_PREFER_ON_FILE,
  },
];
