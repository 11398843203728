import { format } from 'date-fns';
import { DateTime } from 'luxon';
import {
  getNextDateTime,
  getPreviousDateTime,
  isDateTimeBeforeYesterday,
} from './dateTimeHelpers';

export const DATE_ONLY_SYNTAX = 'yyyy-MM-dd';
export const DATE_ONLY_FORMAT = 'MM/dd/yyyy';

export const convertDateOnlyToJSDate = (value: string) => {
  return DateTime.fromISO(value).toJSDate();
};

export const formatDateOnly = (value: string | null | undefined) => {
  if (!value) return '';
  return format(convertDateOnlyToJSDate(value), DATE_ONLY_FORMAT);
};

export const formatDateOnlyAbbr = (value: string | null | undefined) => {
  if (!value) return '';
  return format(convertDateOnlyToJSDate(value), 'MMM d');
};

export const getDateOnlyFromDate = (value: Date) => {
  return format(value, DATE_ONLY_SYNTAX);
};

export const getPreviousDateOnly = (value: string) => {
  return getDateOnlyFromDate(
    getPreviousDateTime(convertDateOnlyToJSDate(value))
  );
};

export const isDateOnlyBeforeYesterday = (value?: string | null) => {
  if (!value) return false;
  return isDateTimeBeforeYesterday(convertDateOnlyToJSDate(value));
};

export const isDateOnlyAnniversaryToday = (value?: string | null) => {
  if (!value) return false;
  const today = format(new Date(), 'MM-dd');
  const birthday = value?.split('-').slice(1).join('-'); // MM-dd
  return today === birthday;
};

export const isPastDateOnly = (date: Date | string) => {
  if(!date) return false;
  if(typeof date === 'string'){
    date = DateTime.fromISO(date).toJSDate();
  }
  const selectedDate = DateTime.fromJSDate(date).toUTC().startOf('day');
  const today = DateTime.now().toUTC().startOf('day');
  return selectedDate < today;
}

export const isDateOnlyBetween = (
  date: Date | string, 
  startDateOnly?: string | null, 
  endDateOnly?: string | null,
) => {
  let startDate, endDate = null;
  if(startDateOnly){
    startDate = DateTime.fromISO(startDateOnly).toUTC().startOf('day');
  }
  if(endDateOnly){
    endDate = DateTime.fromISO(endDateOnly).toUTC().startOf('day');
  }
  if(typeof date === 'string'){
    date = DateTime.fromISO(date).toJSDate();
  }
  const selectedDate = DateTime.fromJSDate(date).toUTC().startOf('day');
  if(startDate && endDate) {
    return selectedDate < startDate || selectedDate > endDate;
  }
  if(startDate){
    return selectedDate < startDate;
  }
  return false;
}

export const getFirstDateNotPastInRange = (
  startDateOnly: Date | string, 
  endDateOnly?: string | null
): Date | undefined => {
  
  let date = (typeof startDateOnly === 'string')
  ? DateTime.fromISO(startDateOnly).toJSDate()
  : startDateOnly;
  
  if(!isPastDateOnly(date)) {
    return date;
  } else {
    return getFirstDateNotPastInRange(getNextDateTime(date), endDateOnly);
  }
  
  return undefined;
}
