import * as UI from '@chakra-ui/react';
import React from 'react';

/**
 * A select input with options prop
 */
export type SelectWithGroupedOptionsProps = {
  options: {
    label: string | undefined;
    options: { 
      label: string | number; 
      value: any }[]; 
    }[];
} & UI.SelectProps;
export const SelectWithGroupedOptions = React.forwardRef<
  HTMLSelectElement,
  SelectWithGroupedOptionsProps
>(({ options, onChange, placeholder = 'Choose one', ...restProps }, ref) => {
  if (restProps.isReadOnly) {
    // The readOnly select is still interactive, so we display this instead
    return (
      <UI.Input
        isReadOnly
        value={options?.find((o) => o.options.find((o) => o.value === restProps.value))?.label}
      />
    );
  }

  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    onChange?.(e);
  };

  return (
    <UI.Select
      ref={ref}
      placeholder={placeholder}
      onChange={handleChange}
      {...restProps}
      value={restProps.value ?? ''}
    >
      {options.map((option) => (
        <optgroup key={JSON.stringify(option.label)} label={option.label}>
          {option.options.map((o) => (
            <option key={JSON.stringify(o.value)} value={o.value}>
              {o.label}
            </option>
          ))}
        </optgroup>
      ))}
    </UI.Select>
  );
});
