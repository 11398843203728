import React from 'react';

import { useAuth } from './AuthProvider';
import { PermissionsEnum } from './types/permissionsEnum';

/**
 * A wrapper component that verifies the user has access to a route.
 */
export const RoleProtect: React.FC<
  React.PropsWithChildren<{
    permission?: PermissionsEnum;
    permissionKey?: keyof typeof PermissionsEnum;
    allowCaregivers?: boolean;
    fallback?: React.ReactNode;
  }>
> = ({ permission, permissionKey, allowCaregivers, fallback, children }) => {
  const auth = useAuth();

  // Check caregiver access
  if (allowCaregivers && auth.userIsCaregiver) {
    <React.Fragment>{children}</React.Fragment>;
  }

  // Check staff access
  return (!permissionKey ||
    auth.hasPermission(PermissionsEnum[permissionKey])) &&
    (!permission || auth.hasPermission(permission)) ? (
    <React.Fragment>{children}</React.Fragment>
  ) : (
    <React.Fragment>{fallback}</React.Fragment>
  );
};
